<template>
  <div class="_bg-default _80w _100vh pb-3 px-3">
    <div v-if="activity" class="_100top">
      <div class="sticky_elmnt">
        <section class="mb-5">
          <h3 class="mb-n3">Activity</h3>
          <v-divider inset></v-divider>
        </section>
        <v-row class="px-3">
          <v-col cols="12" md="2">NAME</v-col>
          <v-col cols="12" md="2">DATE</v-col>
          <v-col cols="12" md="2">TIME</v-col>
          <v-col cols="12" md="4">ACTIVITY</v-col>
        </v-row>
      </div>
      <v-card
        class="radius-card ma-3 pa-3"
        v-for="log in activity"
        :key="log.id"
      >
        <v-row>
          <v-col cols="12" md="2">{{
            log.relationships[0].user.data.nama_lengkap
              ? log.relationships[0].user.data.nama_lengkap
              : "admin"
          }}</v-col>
          <v-col cols="12" md="2">{{
            $date(log.created_at).format("DD-MM-YYYY")
          }}</v-col>
          <v-col cols="12" md="2">{{
            $date(log.created_at).format("HH:ss")
          }}</v-col>
          <v-col cols="12" md="4">{{ log.description }}</v-col>
        </v-row>
      </v-card>
      <div v-if="activity" v-observe-visibility="handleScroll"></div>

      <div v-if="!activity.length" class="d-flex justify-center">
        <section class="pa-2">
          <!-- <img src="../../assets/img/404.svg" height="200px" alt="404" /> -->
          <h3 class="text-center">No Activity</h3>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "logActivity",

  data() {
    return {
      currentPage: 1,
      limit: 10
    };
  },
  computed: {
    ...mapState({
      activity: state => state.admin.activity,
      last: state => state.admin.last_page
    })
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    fetchItem() {
      this.$store.dispatch("admin/log", {
        page: this.currentPage,
        limit: this.limit
      });
    },
    handleScroll(isVisible) {
      if (isVisible) {
        if (this.currentPage <= this.last) {
          this.currentPage++;
          this.fetchItem();
        }
      }
    }
  }
};
</script>

<style scoped>
.sticky_elmnt {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 2;
  background: #eceff1;
  padding: 5px;
  border-radius: 5px;
}
.__overflow__hidden {
  overflow: hidden !important;
  box-sizing: border-box;
}
.__template__cover {
  width: 100%;
  height: 230px;
  background: #f3e5f5;
}
.__relative {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.cover__ {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
